import axios from "axios";
import links from "@/links";
import qs from "qs";
import { PARTNERS_ITEMS_PER_PAGE } from "../constants/partners";

export default {
  getPartners(search, cursorId, active) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (cursorId) {
      params["cursorId"] = cursorId;
    }
    if (active) {
      params["active"] = active;
    }
    params["itemsPerPage"] = PARTNERS_ITEMS_PER_PAGE;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.PARTNERS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },
  getPartner(id) {
    return axios.get(links.PARTNER_LINK(id));
  },

  createPartner(data) {
    return axios.post(links.PARTNERS_LINK(), data);
  },
  updatePartner(data) {
    return axios.put(links.PARTNER_LINK(data.id), data);
  },

  resendInvitation(id) {
    return axios.put(links.PARTNER_RESEND_INVITATION(id));
  },
  resetPasswordReq(id) {
    return axios.put(links.PARTNER_RESET_PASSWORD_REQ(id));
  },
  deactivateAccount(id) {
    return axios.put(links.PARTNER_DEACTIVATE_ACCOUNT(id));
  },
  activateAccount(id) {
    return axios.put(links.PARTNER_ACTIVATE_ACCOUNT(id));
  },
  getStatisticsForPartner(opts = {}) {
    const { dates } = opts;

    let params = {};
    if (dates) {
      params["dates"] = dates;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.PARTNER_STATISTICS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  activateAccountSendEmail(id) {
    return axios.put(links.PARTNER_ACTIVATE_ACCOUNT_SEND_EMAIL(id));
  },
};
