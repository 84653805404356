const DOSSIER_EXPORT_ITEMS_PER_PAGE = 3;

const insurersForValidation = Object.freeze({
  MIC: { key: "mic_leader", name: "MIC" },
  SMALL_DO: { key: "small_do", name: "SMALL DO" },
});

const dossierExportType = Object.freeze({
  DOSSIER_PAID: { key: "dossier_paid", name: "Liste des paiements solde" },
  VALIDATED_CONTRACTS: { key: "validated_contracts", name: "Bordereaux dossiers validés" },
  PAYED_CONTRACTS: { key: "payed_contracts", name: "Bordereaux de commission dossiers payés" },
  DOSSIER_ADVANCE_PAID: { key: "dossier_advance_paid", name: "Liste des paiements d'acompte" },
  SUBSCRIBED_CLIENTS: { key: "subscribed_clients", name: "Liste des clients souscrits" },
  COMPANIES: { key: "companies", name: "Liste des entreprises avec des clients souscrits" },
  DOSSIER_UNPAID: { key: "dossier_unpaid", name: "Liste des dossiers impayés" },
  DOSSIER_PAID_REPAYMENT: {
    key: "dossier_paid_repayment",
    name: "Liste des paiements solde et reversement",
  },
});

const dossierExportStatus = Object.freeze({
  TO_SEND: { key: "to_send", name: "To send" },
  SUCCESS: { key: "success", name: "Success" },
  ERROR: { key: "error", name: "Error" },
  UPLOADED: { key: "uploaded", name: "Uploaded" },
  NOT_UPLOAD: { key: "not_upload", name: "Not upload" },
  TO_UPLOAD: { key: "to_upload", name: "To upload" },
  LOADING: { key: "loading", text: "Loading" },
});

export {
  DOSSIER_EXPORT_ITEMS_PER_PAGE,
  insurersForValidation,
  dossierExportType,
  dossierExportStatus,
};
