import User from "../database/models/User";
import API from "@/api/partners";
import { userRoles } from "@/constants/users";

function getDefaultState() {
  return {
    activeSearchTerm: "",

    hasMorePartners: null,
    cursorForPagination: null,
    statistics: {},
  };
}

export default {
  namespaced: true,
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    setActiveSearchTerm(state, value) {
      state.activeSearchTerm = value;
    },

    setHasMorePartners(state, value) {
      state.hasMorePartners = value;
    },
    setCursorForPagination(state, value) {
      state.cursorForPagination = value;
    },
    resetPaginationOptions(state) {
      state.hasMorePartners = null;
      state.cursorForPagination = null;
    },
    setStatistics(state, value) {
      state.statistics = value;
    },
  },
  actions: {
    fetchPartners({ getters, commit }, payload) {
      const cursorForPagination = getters.getCursorForPagination;
      const cursorId = cursorForPagination ? cursorForPagination.id : undefined;

      return API.getPartners(payload.search, cursorId, false).then(res => {
        const { items, hasMore } = res.data;

        User.insertOrUpdate({
          data: items,
        });

        const lastItemInResponse = items[items.length - 1];
        commit("setCursorForPagination", lastItemInResponse);

        commit("setHasMorePartners", hasMore);
      });
    },
    fetchPartnersAutocomplete(_, payload) {
      return new Promise((resolve, reject) => {
        API.getPartners(payload.search, undefined, true)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getPartner(_, payload) {
      return API.getPartner(payload.id).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    createPartner(_, payload) {
      return API.createPartner(payload).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updatePartner(_, payload) {
      return API.updatePartner(payload).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    deactivateAccount(_, payload) {
      return API.deactivateAccount(payload.id).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    activateAccount(_, payload) {
      return API.activateAccount(payload.id).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    setActiveSearchTerm({ commit }, activeSearchTerm) {
      commit("setActiveSearchTerm", activeSearchTerm);
    },

    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },
    deleteAll() {
      User.delete(user => !!user.roles && user.roles.includes(userRoles.PARTNER));
    },
    statistics({ commit }, params = {}) {
      return API.getStatisticsForPartner(params).then(res => {
        commit("setStatistics", res.data);
      });
    },
    activateAccountSendEmail(_, payload) {
      return API.activateAccountSendEmail(payload.id).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
  getters: {
    getOneForPartnerDetails: () => id => {
      return User.query()
        .where(
          user => user.id === Number(id) && !!user.roles && user.roles.includes(userRoles.PARTNER)
        )
        .first();
    },
    getMultipleForPartnersList() {
      return User.query()
        .where(user => !!user.roles && user.roles.includes(userRoles.PARTNER))
        .orderBy("id", "desc")
        .get();
    },

    getAlphabetically(state) {
      return Object.values(state.statistics)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.name || 0;
          const valueB = b.name || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },

    getActiveSearchTerm(state) {
      return state.activeSearchTerm;
    },
    getHasMorePartners(state) {
      return !!state.hasMorePartners;
    },
    getCursorForPagination(state) {
      return state.cursorForPagination;
    },
    getStatistics(_, getters) {
      const partners = getters.getAlphabetically;
      const chartData = {
        labels: [],
        data: {
          ttc: [],
          fee: [],
        },
      };
      partners.forEach(partner => {
        chartData.labels.push(partner.name);
        chartData.data.ttc.push(partner.ttc);
        chartData.data.fee.push(partner.fee);
      });

      return chartData;
    },
  },
};
