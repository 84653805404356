import DossierAdditionalInfo from "../models/DossierAdditionalInfo";
import API from "@/api/dossierAdditionalInfo";
import { phasesDocs } from "@/constants/dossier.structure";

export default {
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.dossierId).then(res => {
        DossierAdditionalInfo.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createAPI(_, payload) {
      return API.create(payload).then(res => {
        DossierAdditionalInfo.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data).then(res => {
        DossierAdditionalInfo.insertOrUpdate({
          data: res.data,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id).then(() => {
        DossierAdditionalInfo.delete(payload.id);
      });
    },
    fetchForQuestionnaire(_, payload) {
      return API.fetchForQuestionnaire(payload.dossierId).then(res => {
        DossierAdditionalInfo.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.position || 0;
          const valueB = b.position || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },
    getAllActive(_, getters) {
      return getters.getAll.filter(item => item.active);
    },
    countAllActive(_, getters) {
      return getters.getAllActive.length;
    },
    getAllInactive(_, getters) {
      return getters.getAll.filter(item => !item.active);
    },
    getAdditionalInfoQuestionnaire() {
      return DossierAdditionalInfo.query()
        .where(item => {
          return item.rowData.document === phasesDocs.QUESTIONNAIRE.key;
        })
        .first();
    },
  },
};
