import InsurerCommunication from "../models/InsurerCommunication";
import API from "@/api/insurerCommunications";

export default {
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.dossierId).then(res => {
        InsurerCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createAPI(_, payload) {
      return API.create(payload).then(res => {
        InsurerCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data, payload.opts);
    },
    removeAPI(_, payload) {
      return API.delete(payload.id).then(() => {
        InsurerCommunication.delete(payload.id);
      });
    },
    sendMessage(_, payload) {
      return API.sendMessage(payload.id, payload.content, payload.opts).then(res => {
        InsurerCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateStore({ getters }, payload) {
      const data = getters.get(payload.id);
      data.content = payload.content;
      InsurerCommunication.insertOrUpdate({
        data: data,
      });
    },
  },
  getters: {
    get: state => id => {
      return state.data[id] ? { ...state.data[id] } : null;
    },
    getInsurerCommunicationsByDossier: state => dossierId => {
      return Object.values(state.data)
        .filter(item => item.dossierId === dossierId)
        .sort((a, b) => (a.id < b.id ? -1 : 1))
        .map(item => ({ ...item }));
    },
  },
};
